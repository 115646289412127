import '@styles/main.scss';
import CliniciansSlider from './components/clinicians-slider';
import JournalFilters from './components/journal-filters';
import SupportTeamSlider from './components/support-team-slider';

class App {
    constructor() {
        new CliniciansSlider();
        this.mobileMenu();
        new JournalFilters();
        new SupportTeamSlider();
    }

    mobileMenu() {
        const trigger = document.getElementById('mobile-menu-trigger');
        const overlay = document.querySelector('.menu-overlay');
        const menu = document.querySelector('.max-mega-menu');

        if(!trigger) {
            return;
        }

        if(!overlay) {
            return;
        }

        if(!menu) {
            return;
        }

        const close = () => {
            menu.classList.remove('is-active');
            trigger.classList.remove('is-active');
            document.documentElement.classList.remove('menu-open');
            overlay.classList.remove('is-active');
        }

        trigger.addEventListener('click', () => {
            menu.classList.toggle('is-active');
            trigger.classList.toggle('is-active');
            document.documentElement.classList.toggle('has-menu-open');
            overlay.classList.toggle('is-active');
        });

        overlay.addEventListener('click', () => {
            close();
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
