import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import "swiper/swiper-bundle.css";

export default class SupportTeamSlider {
    constructor() {
        const wrapper = document.querySelectorAll('.support-team-widget__swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const WalkGallerySwiper = new Swiper('.support-swiper', {

                navigation: {
                    nextEl: ".swiper-button-next-support",
                    prevEl: ".swiper-button-prev-support"
                },

                slidesPerView: 3,
                spaceBetween: 20,
                loop: true,

                breakpoints: {
                    768: { 
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    0: { 
                        slidesPerView: 1,
                        spaceBetween: 10,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                    }
                },

                modules: [ Navigation, Pagination ],

            })
        });
    }
}