export default class JournalFilters {
    constructor() {
        const trigger = document.getElementById('blogs-filter-by-trigger');
        const content = document.getElementById('filter-category-list')

        if(!trigger) return

        if(!content) return

        trigger.addEventListener('click', function() {
            if(content.classList.contains('is-active')) {
                content.classList.remove('is-active')
            } else {
                content.classList.add('is-active');
            }
        })
    }
}